img.landing-title {
width: 90.5vw;
height: auto;
margin-left: 4vw;
margin-top: 3vw;
/* margin: 0 auto; */


}


.land-logos {
margin-left: 16vw;
transition: .5s;


}
/* .b-logo {
padding: .5vw;

}
.m-logo {
padding: 1.4vw;

} */

.l-logo, .b-logo, .m-logo {
padding: 1vw;
width: 15vw;
margin-left: 4vw;
margin-right: 4vw;
transition: .5s;
border: 2px dashed white;
border-radius: 50%;
margin-bottom: 2vw;
}
.l-logo:hover {
transform: scale(1.02);
transform: translateX(.5vw) rotate(360deg);
}
.b-logo:hover {
  transform: scale(1.02);
  transform: translateX(.5vw) rotate(360deg);
  }
  .m-logo:hover {
    transform: scale(1.02);
    transform: translateX(.5vw) rotate(360deg);
    }
.fanny {
margin: 0 auto;
/* margin-left: 10vw; */
text-align: center;
font-size: 1.5vw;
font-family: 'Be Vietnam', sans-serif;
margin-bottom: 5vw;
}

.bg {
  animation: slide 7s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:7s;
}

.bg3 {
  animation-duration:7s;
}




@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

@media only screen and (max-width: 700px) {
img.landing-title {
   min-width: 125%; 
   margin-left: -10vw;
  }
  .l-logo, .b-logo, .m-logo {
  min-width: 45%; 
  margin-left: 16vw;
  }
  .fanny {
  font-size: 5vw;
  margin-left: 5vw;
  }
}
