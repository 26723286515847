.burger-boi {
font-size: 2vw;
color: #545454;
/* color: #d09db2; */
float: right;
right: 0;
padding-right: 2vw;
margin-top: 1vw;
position: fixed;

}
.burger-boi:hover {

cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .burger-boi {
     font-size: 5vw; 
     
    }
    .burger-boi:hover {
      cursor: pointer;
      }
    
  }
  