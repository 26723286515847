.about-cont {
background-color: #FDF6EF;
padding: 2vw;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;
font-size: 1.1vw;
width: 55vw;
height: auto;
margin-left: 31vw;
margin-top: -38vw;
margin-bottom: 5vw;
box-shadow: 5px 5px .5px .5px #d09db2b3;
}
.me-pic {
width: 31vw;
height: auto;
}

.bold {
font-weight: bolder;
}
.a-tt {
  width: 34.5vw;

margin-bottom: 8vw;
margin-top: 3vw;
margin-left: 2vw;
animation-duration: 3s;
animation-name: slidein;
animation-iteration-count: inherit;
}
@keyframes slidein {
  from {
    margin-left: 10%;
   
  }

  to {
    margin-right: 20%;
   
  }
}

.skills {
font-size: 2vw;
color: #545454;
display: flex;
justify-content: space-between;
width: 52vw;
margin-left: 32vw;
margin-top: -4vw;
margin-bottom: 5vw;
}

.social {
font-size: 7vw;
float: right;
width: 3vw;
height: auto;

margin-top: -42vw;
margin-right: 8vw;

}
.soc {
margin-top: 1vw;
margin-bottom: 1vw;
padding: 1vw;
width: 10vw;
border-radius: 45%;
color:  #545454d0; 
border: 2px dashed white;
background-clip: content-box;
transition: .5s;

}
.soc:hover {
color: #fff3f3;
cursor: pointer;
transform: translateX(.5vw) rotate(360deg);
}

.file2 {
font-size: 2.5vw;
}
.file2:hover {
color: rgb(249, 237, 237);
cursor: pointer;
}
.filezz {
  margin-left: -24vw;
 
  margin-top: 6vw;
  /* margin-bottom: 2.5vw; */
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: 1vw;
  /* color:var(--colorShadeA); */
  color: #545454cf;
  font-weight: 700;
  text-transform: uppercase;
  font-family: inherit;
  text-align: center;
  
}
.lol {
  text-decoration: none;
  font-size: 1vw;
  
  color: #545454cf;
  font-weight: 700;
  text-transform: uppercase;
  font-family: inherit;
  text-align: center; 
}

.filezz {
   padding: 1.4em 2em;
   border: 2px solid var(--colorShadeA);
  border-radius: 1em;
  background: var(--colorShadeE);
  transform-style: preserve-3d;
   transition: all 175ms cubic-bezier(0, 0, 1, 1);
}
.filezz::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeC);
  border-radius: inherit;
    box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
 transform: translate3d(0, 0.75em, -1em);
     transition: all 175ms cubic-bezier(0, 0, 1, 1);
}


.filezz:hover {
  background: var(--colorShadeD);
  transform: translate(0, 0.375em);
}

.filezz:hover::before {
  transform: translate3d(0, 0.75em, -1em);
}

.filezz:active {
            transform: translate(0em, 0.75em);
}

.filezz:active::before {
  transform: translate3d(0, 0, -1em);
  
      box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);

}

/* 
.bb {
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
} */
.bb {
  /* bottom:0;
  opacity:.5;
  position:fixed;
  top:0;
  left: 1; 
  z-index:-1; */
  margin-left: 15vw;
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  -webkit-animation: slidein 4s forwards;
  
  animation: slidein 4s forwards;
  
}

@-webkit-keyframes slidein {
  100% { left: 0; }
}

@keyframes slidein {
  0% { right: 0; }
}


@media only screen and (max-width: 700px) {
  .a-tt{
     min-width: 45%;
     
     
    
    }
    .me-pic {
    min-width: 55%;
   margin-left: 5vw;
    
    
    }
    .about-cont {
    margin-top: 15vw;
    min-width: 80%;
    font-size: 2.5vw;
    margin-left: 10vw;
    }
    .social {
    margin-top: -195vw;
    margin-right: 20vw;
    font-size: 15vw;
    }
    .filezz {
      min-width: 30%;
      height: auto;
      font-size: 2vw;
      margin-top: 15vw;
    }
    .skills {
    font-size: 5vw;
    margin-left: 25vw;
    display: flex;
    justify-content: space-between;
    
    }
  }
