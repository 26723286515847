@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
background: #F2CAC3;
}
img.landing-title {
width: 90.5vw;
height: auto;
margin-left: 4vw;
margin-top: 3vw;
/* margin: 0 auto; */


}


.land-logos {
margin-left: 16vw;
transition: .5s;


}
/* .b-logo {
padding: .5vw;

}
.m-logo {
padding: 1.4vw;

} */

.l-logo, .b-logo, .m-logo {
padding: 1vw;
width: 15vw;
margin-left: 4vw;
margin-right: 4vw;
transition: .5s;
border: 2px dashed white;
border-radius: 50%;
margin-bottom: 2vw;
}
.l-logo:hover {
transform: scale(1.02);
transform: translateX(.5vw) rotate(360deg);
}
.b-logo:hover {
  transform: scale(1.02);
  transform: translateX(.5vw) rotate(360deg);
  }
  .m-logo:hover {
    transform: scale(1.02);
    transform: translateX(.5vw) rotate(360deg);
    }
.fanny {
margin: 0 auto;
/* margin-left: 10vw; */
text-align: center;
font-size: 1.5vw;
font-family: 'Be Vietnam', sans-serif;
margin-bottom: 5vw;
}

.bg {
  animation: slide 7s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:7s;
}

.bg3 {
  animation-duration:7s;
}




@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

@media only screen and (max-width: 700px) {
img.landing-title {
   min-width: 125%; 
   margin-left: -10vw;
  }
  .l-logo, .b-logo, .m-logo {
  min-width: 45%; 
  margin-left: 16vw;
  }
  .fanny {
  font-size: 5vw;
  margin-left: 5vw;
  }
}

.menu {
float: right; 
 margin-right: 5vw;
margin-top: 2vw;
color: #545454;
font-size: 1.5vw;
position: fixed;
right: 95px;

}

.menu-link {
  padding-left: 2vw;
  padding-right: 2vw;
  color: #545454;
  text-decoration: none;
  font-family: 'Be Vietnam', sans-serif;
  
 

}
.menu-link:hover{
color: #d09db2;
font-size: 1.4vw;
border: 2px dashed white;
background-clip: content-box;
}

.menu-enter {
  transform: translateX(50%);
 
}
.menu-enter-active {
  transform: translateX(0);
  transition: transform 0.4s linear;
}
.menu-exit {
  transform: translateX(0);
}
.menu-exit-active {
  transform: translateX(50%);
  transition: transform 0.4s linear;
}

@media only screen and (max-width: 700px) {
  .menu {
     font-size: 2.7vw;
     font-weight: bolder; 
     
    }
    /* .menu-enter {
      transform: translateX(50%);
     
    } */
    .menu-link:hover{
      color: #d09db2;
      font-size: 2.4vw;
      border: 2px dashed white;
      background-clip: content-box;
      }
      
    
  }
.burger-boi {
font-size: 2vw;
color: #545454;
/* color: #d09db2; */
float: right;
right: 0;
padding-right: 2vw;
margin-top: 1vw;
position: fixed;

}
.burger-boi:hover {

cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .burger-boi {
     font-size: 5vw; 
     
    }
    .burger-boi:hover {
      cursor: pointer;
      }
    
  }
  

.about-cont {
background-color: #FDF6EF;
padding: 2vw;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;
font-size: 1.1vw;
width: 55vw;
height: auto;
margin-left: 31vw;
margin-top: -38vw;
margin-bottom: 5vw;
box-shadow: 5px 5px .5px .5px #d09db2b3;
}
.me-pic {
width: 31vw;
height: auto;
}

.bold {
font-weight: bolder;
}
.a-tt {
  width: 34.5vw;

margin-bottom: 8vw;
margin-top: 3vw;
margin-left: 2vw;
animation-duration: 3s;
animation-name: slidein;
animation-iteration-count: inherit;
}
@keyframes slidein {
  from {
    margin-left: 10%;
   
  }

  to {
    margin-right: 20%;
   
  }
}

.skills {
font-size: 2vw;
color: #545454;
display: flex;
justify-content: space-between;
width: 52vw;
margin-left: 32vw;
margin-top: -4vw;
margin-bottom: 5vw;
}

.social {
font-size: 7vw;
float: right;
width: 3vw;
height: auto;

margin-top: -42vw;
margin-right: 8vw;

}
.soc {
margin-top: 1vw;
margin-bottom: 1vw;
padding: 1vw;
width: 10vw;
border-radius: 45%;
color:  #545454d0; 
border: 2px dashed white;
background-clip: content-box;
transition: .5s;

}
.soc:hover {
color: #fff3f3;
cursor: pointer;
transform: translateX(.5vw) rotate(360deg);
}

.file2 {
font-size: 2.5vw;
}
.file2:hover {
color: rgb(249, 237, 237);
cursor: pointer;
}
.filezz {
  margin-left: -24vw;
 
  margin-top: 6vw;
  /* margin-bottom: 2.5vw; */
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: 1vw;
  /* color:var(--colorShadeA); */
  color: #545454cf;
  font-weight: 700;
  text-transform: uppercase;
  font-family: inherit;
  text-align: center;
  
}
.lol {
  text-decoration: none;
  font-size: 1vw;
  
  color: #545454cf;
  font-weight: 700;
  text-transform: uppercase;
  font-family: inherit;
  text-align: center; 
}

.filezz {
   padding: 1.4em 2em;
   border: 2px solid var(--colorShadeA);
  border-radius: 1em;
  background: var(--colorShadeE);
  transform-style: preserve-3d;
   transition: all 175ms cubic-bezier(0, 0, 1, 1);
}
.filezz::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeC);
  border-radius: inherit;
    box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
 transform: translate3d(0, 0.75em, -1em);
     transition: all 175ms cubic-bezier(0, 0, 1, 1);
}


.filezz:hover {
  background: var(--colorShadeD);
  transform: translate(0, 0.375em);
}

.filezz:hover::before {
  transform: translate3d(0, 0.75em, -1em);
}

.filezz:active {
            transform: translate(0em, 0.75em);
}

.filezz:active::before {
  transform: translate3d(0, 0, -1em);
  
      box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);

}

/* 
.bb {
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
} */
.bb {
  /* bottom:0;
  opacity:.5;
  position:fixed;
  top:0;
  left: 1; 
  z-index:-1; */
  margin-left: 15vw;
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  
  animation: slidein 4s forwards;
  
}

@keyframes slidein {
  0% { right: 0; }
}


@media only screen and (max-width: 700px) {
  .a-tt{
     min-width: 45%;
     
     
    
    }
    .me-pic {
    min-width: 55%;
   margin-left: 5vw;
    
    
    }
    .about-cont {
    margin-top: 15vw;
    min-width: 80%;
    font-size: 2.5vw;
    margin-left: 10vw;
    }
    .social {
    margin-top: -195vw;
    margin-right: 20vw;
    font-size: 15vw;
    }
    .filezz {
      min-width: 30%;
      height: auto;
      font-size: 2vw;
      margin-top: 15vw;
    }
    .skills {
    font-size: 5vw;
    margin-left: 25vw;
    display: flex;
    justify-content: space-between;
    
    }
  }

.works {
  width: 40vw;
 margin-top: 1.1vw;
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: inherit;
  }
  @keyframes slidein {
    from {
      margin-left: 30%;
     
    }
  
    to {
      margin-right: 20%;
     
    }
  }

  .p1 {
  margin-top: 2vw;
  }

  .p {
    width: 31.4vw;
    height: auto;
    margin-bottom: 5vw;
    margin-top: 2vw;
    transition: transform .4s ease-in-out;
}
.p:hover {
transform: rotate(20deg);
    /* transform: scale(1.2); */
}
  .p-words {
  width: 50vw;
  height: 24vw;
  background-color: #FDF6EF;
  color: #545454;
  text-decoration: none;
  font-family: 'Be Vietnam', sans-serif;
  font-size: 1.1vw;
  margin-bottom: 5vw;
  margin-top: -34vw;
  margin-left: 35vw;
  display: flex;
  box-shadow: 5px 5px .5px .5px #d09db2b3;


  }
  .p-words:hover{
  transform: scale(1.01);
  
 
  }
  :root {
    --backgroundColor: rgba(246, 241, 209);
    --colorShadeA: #cd8ca7;
    --colorShadeB: #d79dad;
    --colorShadeC: #f2bbca;
    --colorShadeD: #f0c8d2;
    --colorShadeE: #ffe3e2;
  }
  * {
    box-sizing: border-box;
  }
  *::before, *::after {
    box-sizing: border-box;
  }
 .big-button {
    
    margin-left: 12vw;
    height: 3vw;
    margin-top: 3vw;
    margin-bottom: 6.5vw;
    
    cursor: pointer;
    outline: none;
    
    vertical-align: middle;
    text-decoration: none;
    font-size: .7vw;
    color:#cd8ca7;
    color:var(--colorShadeA);
    font-weight: 700;
    text-transform: uppercase;
    font-family: inherit;
   
  } 
  
 .big-button {
     padding: 1em 2em;
     border: 2px solid #cd8ca7;
     border: 2px solid var(--colorShadeA);
    border-radius: 1em;
    background: #ffe3e2;
    background: var(--colorShadeE);
  transform-style: preserve-3d;
     transition: all 175ms cubic-bezier(0, 0, 1, 1);
     

  }
.big-button::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f2bbca;
    background: var(--colorShadeC);
    border-radius: inherit;
      box-shadow: 0 0 0 2px #d79dad, 0 0.75em 0 0 #cd8ca7;
      box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
   transform: translate3d(0, 0.75em, -1em);
       transition: all 175ms cubic-bezier(0, 0, 1, 1);
  }
  
  
  .big-button:hover {
    background: #f0c8d2;
    background: var(--colorShadeD);
    transform: translate(0, 0.375em);
  }
  
  .big-button:hover::before {
    transform: translate3d(0, 0.75em, -1em);
  }
  
 .big-button:active {
              transform: translate(0em, 0.75em);
  }
  
 .big-button:active::before {
    transform: translate3d(0, 0, -1em);
    
        box-shadow: 0 0 0 2px #d79dad, 0 0.25em 0 0 #d79dad;
    
        box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
  
  }
  .bb {
    background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  .aboutp {
  padding: .7vw;
  font-weight: bolder;
  }
  .extra {
  font-weight: normal;
  }
  @media only screen and (max-width: 700px) {
    .p {
       min-width: 75%; 
       margin-left: 10vw;
       
      }
    .p-words {
    min-width: 80%;
    height: auto;
    font-size: 2.5vw;
    margin-left: 10vw;
    margin-top: -2vw;
    }
    .big-button {
    /* min-width: 25%; */
    font-size: 2vw;
    height: auto;
    }
      
    }

.contact-form {
width: 45vw;
height: 32vw;
background-color:  #FDF6EF;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;
/* font-weight: bolder; */
font-size: 2vw;
margin: 0 auto;
margin-top: 3vw;
text-align: center;
margin-bottom: 2vw;
box-shadow: 4px 4px .2px .2px #d09db2b3;

}
label {
font-size: 1.5vw;
}

.plane {
  position: absolute;
  left: -600px;
  margin-left: 5vw;
  margin-top: 5vw;
  width: 26.1vw;
  height: auto;
  
  
 
  
  animation: slidey 3s forwards;
  
}

/* @-webkit-keyframes slide {
  100% { left: 0; }
} */

@keyframes slidey {
  100% { left: 0; }
}

.talk {
  width: 24.1vw;
  margin-left: 1vw;
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: inherit;
  }
  @keyframes slidein {
    from {
      margin-left: 15%;
     
    }
  
    to {
      margin-right: 25%;
     
    }
  }

input {
border: none;
width: 20vw;
height: 2vw;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;

font-size: 1vw;
}
textarea {
border: none;
width: 20vw;
height: 6.5vw;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;
font-weight: bolder;
font-size: 1vw;
}
button.big {
  padding: 1em 2em;
  border: 2px solid var(--colorShadeA);
 border-radius: 1em;
 background: var(--colorShadeE);
transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
  margin: 0 auto;
  margin-top: 1vw;
}
button.big::before {
 position: absolute;
 content: '';
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background: var(--colorShadeC);
 border-radius: inherit;
   box-shadow: 0 0 0 .01vw var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
transform: translate3d(0, 0.75em, -1em);
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}


button.big:hover {
 background: var(--colorShadeD);
 transform: translate(0, 0.375em);
}

button.big:hover::before {
 transform: translate3d(0, 0.75em, -1em);
}

button.big:active {
           transform: translate(0em, 0.75em);
}

button.big:active::before {
 transform: translate3d(0, 0, -1em);
 
     box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);

}
.bb {
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}
.form-container {
margin-top: 1vw;
}
.meetmee {
font-size: 12vw;
padding: 1.7vw;
height: 15vw;
/* padding-top: 1.9vw; */
border-radius: 50%;
color:  #545454d0; 
border: 2px dashed white;
background-clip: content-box;
transition: .4s;




}
.meetmee:hover {
color: #fff3f3;
cursor: pointer;
transform: translateX(.5vw) rotate(361deg);
}


.meeting {
  margin-top: -30vw;
  margin-left: 71vw;
  text-align: center;
  font-family: 'Be Vietnam', sans-serif;
 font-weight: bold;
 text-transform: lowercase;
}
.pm {
  text-transform: lowercase;
}
@media only screen and (max-width: 700px) {
  .talk {
     min-width: 50%; 
    
     
    }
  .plane {
margin-left: -1vw;
  
  }
  .contact-form {
  min-width: 68%;
  font-size: 7vw;
  height: auto;
  padding-bottom: 10vw;
  }
  label, input, textarea {
  font-size: 5vw;
  min-width: 80%;
  height: auto;
  }
  .meetmee {
   margin-top: 30vw;
   margin-left: -45vw;
   
  }
  .pm {
   font-size: 4vw;
   margin-left: -45vw;
  }
  .meeting {
  width: 0vw;
  }
    
  }
