
.contact-form {
width: 45vw;
height: 32vw;
background-color:  #FDF6EF;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;
/* font-weight: bolder; */
font-size: 2vw;
margin: 0 auto;
margin-top: 3vw;
text-align: center;
margin-bottom: 2vw;
box-shadow: 4px 4px .2px .2px #d09db2b3;

}
label {
font-size: 1.5vw;
}

.plane {
  position: absolute;
  left: -600px;
  margin-left: 5vw;
  margin-top: 5vw;
  width: 26.1vw;
  height: auto;
  
  
 
  
  animation: slidey 3s forwards;
  
}

/* @-webkit-keyframes slide {
  100% { left: 0; }
} */

@keyframes slidey {
  100% { left: 0; }
}

.talk {
  width: 24.1vw;
  margin-left: 1vw;
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: inherit;
  }
  @keyframes slidein {
    from {
      margin-left: 15%;
     
    }
  
    to {
      margin-right: 25%;
     
    }
  }

input {
border: none;
width: 20vw;
height: 2vw;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;

font-size: 1vw;
}
textarea {
border: none;
width: 20vw;
height: 6.5vw;
color: #545454;
text-decoration: none;
font-family: 'Be Vietnam', sans-serif;
font-weight: bolder;
font-size: 1vw;
}
button.big {
  padding: 1em 2em;
  border: 2px solid var(--colorShadeA);
 border-radius: 1em;
 background: var(--colorShadeE);
transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
  margin: 0 auto;
  margin-top: 1vw;
}
button.big::before {
 position: absolute;
 content: '';
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background: var(--colorShadeC);
 border-radius: inherit;
   box-shadow: 0 0 0 .01vw var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
transform: translate3d(0, 0.75em, -1em);
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}


button.big:hover {
 background: var(--colorShadeD);
 transform: translate(0, 0.375em);
}

button.big:hover::before {
 transform: translate3d(0, 0.75em, -1em);
}

button.big:active {
           transform: translate(0em, 0.75em);
}

button.big:active::before {
 transform: translate3d(0, 0, -1em);
 
     box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);

}
.bb {
  background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}
.form-container {
margin-top: 1vw;
}
.meetmee {
font-size: 12vw;
padding: 1.7vw;
height: 15vw;
/* padding-top: 1.9vw; */
border-radius: 50%;
color:  #545454d0; 
border: 2px dashed white;
background-clip: content-box;
transition: .4s;




}
.meetmee:hover {
color: #fff3f3;
cursor: pointer;
transform: translateX(.5vw) rotate(361deg);
}


.meeting {
  margin-top: -30vw;
  margin-left: 71vw;
  text-align: center;
  font-family: 'Be Vietnam', sans-serif;
 font-weight: bold;
 text-transform: lowercase;
}
.pm {
  text-transform: lowercase;
}
@media only screen and (max-width: 700px) {
  .talk {
     min-width: 50%; 
    
     
    }
  .plane {
margin-left: -1vw;
  
  }
  .contact-form {
  min-width: 68%;
  font-size: 7vw;
  height: auto;
  padding-bottom: 10vw;
  }
  label, input, textarea {
  font-size: 5vw;
  min-width: 80%;
  height: auto;
  }
  .meetmee {
   margin-top: 30vw;
   margin-left: -45vw;
   
  }
  .pm {
   font-size: 4vw;
   margin-left: -45vw;
  }
  .meeting {
  width: 0vw;
  }
    
  }