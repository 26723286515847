.works {
  width: 40vw;
 margin-top: 1.1vw;
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: inherit;
  }
  @keyframes slidein {
    from {
      margin-left: 30%;
     
    }
  
    to {
      margin-right: 20%;
     
    }
  }

  .p1 {
  margin-top: 2vw;
  }

  .p {
    width: 31.4vw;
    height: auto;
    margin-bottom: 5vw;
    margin-top: 2vw;
    -webkit-transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
}
.p:hover {
-webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    /* transform: scale(1.2); */
}
  .p-words {
  width: 50vw;
  height: 24vw;
  background-color: #FDF6EF;
  color: #545454;
  text-decoration: none;
  font-family: 'Be Vietnam', sans-serif;
  font-size: 1.1vw;
  margin-bottom: 5vw;
  margin-top: -34vw;
  margin-left: 35vw;
  display: flex;
  box-shadow: 5px 5px .5px .5px #d09db2b3;


  }
  .p-words:hover{
  transform: scale(1.01);
  
 
  }
  :root {
    --backgroundColor: rgba(246, 241, 209);
    --colorShadeA: #cd8ca7;
    --colorShadeB: #d79dad;
    --colorShadeC: #f2bbca;
    --colorShadeD: #f0c8d2;
    --colorShadeE: #ffe3e2;
  }
  
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");
  * {
    box-sizing: border-box;
  }
  *::before, *::after {
    box-sizing: border-box;
  }
 .big-button {
    
    margin-left: 12vw;
    height: 3vw;
    margin-top: 3vw;
    margin-bottom: 6.5vw;
    
    cursor: pointer;
    outline: none;
    
    vertical-align: middle;
    text-decoration: none;
    font-size: .7vw;
    color:var(--colorShadeA);
    font-weight: 700;
    text-transform: uppercase;
    font-family: inherit;
   
  } 
  
 .big-button {
     padding: 1em 2em;
     border: 2px solid var(--colorShadeA);
    border-radius: 1em;
    background: var(--colorShadeE);
  transform-style: preserve-3d;
     transition: all 175ms cubic-bezier(0, 0, 1, 1);
     

  }
.big-button::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--colorShadeC);
    border-radius: inherit;
      box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
   transform: translate3d(0, 0.75em, -1em);
       transition: all 175ms cubic-bezier(0, 0, 1, 1);
  }
  
  
  .big-button:hover {
    background: var(--colorShadeD);
    transform: translate(0, 0.375em);
  }
  
  .big-button:hover::before {
    transform: translate3d(0, 0.75em, -1em);
  }
  
 .big-button:active {
              transform: translate(0em, 0.75em);
  }
  
 .big-button:active::before {
    transform: translate3d(0, 0, -1em);
    
        box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
  
  }
  .bb {
    background-image: linear-gradient(-60deg,  #e4b1b2 50%,  #F2CAC3 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  .aboutp {
  padding: .7vw;
  font-weight: bolder;
  }
  .extra {
  font-weight: normal;
  }
  @media only screen and (max-width: 700px) {
    .p {
       min-width: 75%; 
       margin-left: 10vw;
       
      }
    .p-words {
    min-width: 80%;
    height: auto;
    font-size: 2.5vw;
    margin-left: 10vw;
    margin-top: -2vw;
    }
    .big-button {
    /* min-width: 25%; */
    font-size: 2vw;
    height: auto;
    }
      
    }