.menu {
float: right; 
 margin-right: 5vw;
margin-top: 2vw;
color: #545454;
font-size: 1.5vw;
position: fixed;
right: 95px;

}

.menu-link {
  padding-left: 2vw;
  padding-right: 2vw;
  color: #545454;
  text-decoration: none;
  font-family: 'Be Vietnam', sans-serif;
  
 

}
.menu-link:hover{
color: #d09db2;
font-size: 1.4vw;
border: 2px dashed white;
background-clip: content-box;
}

.menu-enter {
  transform: translateX(50%);
 
}
.menu-enter-active {
  transform: translateX(0);
  transition: transform 0.4s linear;
}
.menu-exit {
  transform: translateX(0);
}
.menu-exit-active {
  transform: translateX(50%);
  transition: transform 0.4s linear;
}

@media only screen and (max-width: 700px) {
  .menu {
     font-size: 2.7vw;
     font-weight: bolder; 
     
    }
    /* .menu-enter {
      transform: translateX(50%);
     
    } */
    .menu-link:hover{
      color: #d09db2;
      font-size: 2.4vw;
      border: 2px dashed white;
      background-clip: content-box;
      }
      
    
  }